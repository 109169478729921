.nav-link-container {
  padding: 10px 0px 10px 0px;
  max-height: 42px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 300px;
  margin-top: -13px;
  margin-bottom: 18px;
}

.nav-item {
  display: inline-block;
  margin: auto;
  vertical-align: middle;
  padding: 0px 10px 0px 10px;
}

p.nav-item {
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  font-family: barlowRegular;
}
