.error-msg-container {
  background-color: #fff2f4;
  border: 1px solid #ef0000;
  border-left: 5px solid #ef0000;
}
.alert-msg-container {
  background-color: #f1f8ea;
  border: 1px solid #4c823f;
  border-left: 5px solid #4c823f;
}

.msg-container {
  max-width: 915px;
  display: flex;
  align-items: baseline;
  margin: 0 auto;
  margin-bottom: 16px;
  border-radius: 2px;
  padding: 16px 58px 16px 0px;
}

.msg-container p {
  font-size: 16px;
  font-weight: 400;
  font-family: barlowRegular;
  line-height: 20px;
  text-align: left;
  margin: 0;
  margin-left: 55px;
}

.error-msg-container p {
  color: #ef0000;
}

.msg-container .msg-icon {
  position: absolute;
  margin-left: 20px;
}

.msg-container .close-icon {
  position: absolute;
  margin-left: 320px;
}

@media (max-width: 320px) {
  .msg-container {
    width: 282px;
  }
  .msg-container .close-icon {
    margin-left: 254px;
  }
  .msg-container p {
    margin-left: 50px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .msg-container {
    width: 280px;
  }
  .msg-container .close-icon {
    margin-left: 310px;
    margin-top: 6px;
  }
  .msg-container .msg-icon {
    /* margin-bottom: 14px; */
  }
  .msg-container p {
    margin-left: 55px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .msg-container {
    max-width: 395px;
  }
  .msg-container .msg-icon {
    top: 152px;
  }
  .msg-container .close-icon {
    top: 160px;
  }
}

@media (min-width: 1024px) {
  .msg-container .msg-icon {
    margin-left: 16px;
  }

  .msg-container {
    text-align: left;
  }

  .msg-container p {
    margin-left: 50px;
  }

  .msg-container .close-icon {
    margin-left: 880px;
  }
}
