.btn-confirm {
  background: black;
  color: white;
  border: none;
  margin-bottom: 8px;
}

.btn-cancel {
  background: white;
  color: black;
  border: 1px solid black;
}

.disabled {
  background-color: #9d9d9d;
}

.submit-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-inline: auto;
}

.submit-buttons button {
  cursor: pointer;
  font-size: 18px;
  line-height: 32px;
  border-radius: 2px;
  font-family: barlowMedium;
  letter-spacing: 0.75px;
  font-weight: 500;
  height: 48px;
}

.save-btn-only {
  display: grid;
  margin-inline: auto;
}

.save-btn-only button {
  cursor: pointer;
  font-size: 18px;
  line-height: 32px;
  border-radius: 2px;
  font-family: barlowMedium;
  font-weight: 500;
  height: 48px;
}

.save-disabled {
  background-color: #f2f2f2;
  color: #9d9d9d;
  border: 1px solid #9d9d9d;
}

.submit-btn-hidden {
  display: none;
}

.submit-buttons button:hover {
  opacity: 0.8;
}

.submit-buttons button:active {
  opacity: 0.64;
}

.btn-submit {
  background: black;
  color: white;
  border: none;
}

.btn-save {
  background: white;
  color: black;
  border: 1px solid black;
}

.btn-approve {
  color: white;
  background: black;
  border: 1px solid black;
}
@media (min-width: 360px) and (max-width: 767px) {
  .submit-buttons button {
    font-size: 16px;
    height: 36px;
  }
}

@media (min-width: 768px) {
  .confirm-buttons-section button {
    width: 194px;
  }

  .btn-confirm {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .submit-buttons {
    width: 400px;
  }
  .confirm-section {
    width: 486px;
  }

  .save-btn-only {
    max-width: 360px;
  }
}

@media (min-width: 1024px) {
  .submit-buttons {
    max-width: 660px;
  }
  .save-btn-only button {
    width: 318px;
    margin-left: 85px;
  }
}
