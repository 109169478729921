.Standard-spinner {
  left: calc(50% - 25px);
  position: absolute;
  width: 48px;
  height: 48px;
  fill: #000;
}
.spinner-container {
  margin-top: 35px;
}
