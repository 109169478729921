.page-container {
  padding: 24px 0 30px;
}
.error-txt {
  color: red;
}
.section-table-options .checkbox input:checked + label {
  font-family: barlowMedium;
  font-weight: 500;
}

.confirm-section-header {
  background-color: #ffffff;
}

.confirm-section-header p {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding: 15px;
  margin: 0;
}

.confirm-buttons-section {
  background-color: #ffffff;
  padding: 16px;
  width: 100%;
  bottom: 0;
  width: -webkit-fill-available;
}

.confirm-buttons-section button {
  cursor: pointer;
  font-family: barlowRegular;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  border-radius: 2px;
  display: block;
  width: 100%;
  height: 48px;
}

.section-tables {
  display: grid;
  gap: 20px;
  grid-gap: 20px;
  justify-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 880px;
}

.section-tables-wrapper {
  margin: 0 auto;
  padding: 0px auto;
}

.section-table-container {
  max-width: 486px;
  text-align: left;
  padding: 0 16px;
}

.section-table-container.open svg {
  transform: rotate(-90deg);
}

.section-table-container svg {
  transform: rotate(90deg);
}

.section-table-options {
  border: 1px solid black;
  border-top: 0;
  padding: 20px;
}

.section-table-options.hidden {
  display: none;
}

.heading {
  border: 1px solid black;
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto 20px;
  align-items: center;
  font-family: barlowMedium;
}

.heading h3 {
  font-size: 16px;
  font-family: barlowMedium;
  font-weight: 500;
}

.trainer-heading {
  margin-top: 0px;
  font-size: 16px;
  font-family: barlowMedium;
  font-weight: 500;
}

.heading img {
  width: 12px;
  transition: 0.3s transform;
}

.nandoca-compliance-msg {
  font-family: barlowMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1px;
  color: #000000;
  padding: 0 16px;
}

.submit-buttons-wrapper {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #9d9d9d;
}
.nav-link-container-form {
  height: 200px;
  padding: 0px 0px 2px 0px;
  max-height: 42px;
  margin: 0 auto;
  position: relative;
  max-width: 940px;
}

p.last-saved-date {
  text-align: center;
  font-size: 12px;
  line-height: 32px;
  font-family: barlowRegular;
}
span.last-saved-date {
  font-family: barlowBold;
}
@media (max-width: 320px) {
  .section-table-container {
    width: 282px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .section-table-container {
    width: 345px;
  }

  .tablet-desktop-only {
    display: none;
  }

  .confirm-buttons-section {
    position: fixed;
  }

  .confirm-section-header p {
    font-family: "barlowMedium";
  }
  .nav-link-container-form {
    padding: 0px 0px 6px 0px;
    max-width: 365px;
  }
}

@media (max-width: 767px) {
  .confirm-section {
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 136px;
  }
}

@media (max-width: 1023px) {
  .page-container h1 {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .heading,
  .section-table-options {
    background: white;
  }
  .confirm-section {
    width: 100%;
  }
  .nav-link-container-form {
    padding: 0px 0px 6px 0px;
    max-width: 365px;
  }
}

@media (min-width: 768px) {
  .confirm-section-content {
    height: 351px;
  }
  .confirm-buttons-section {
    display: flex;
    gap: 6.85px;
    justify-content: center;
    height: 95px;
    padding: 0;
    align-items: center;
  }
  .submit-buttons-wrapper {
    margin-top: 24px;
    padding-top: 24px;
  }
  .heading h3 {
    font-size: 18px;
  }
  .confirm-section {
    width: 616px;
    position: fixed;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);
    z-index: 2;
    background-color: #f2f2f2;
  }

  .confirm-section-header {
    background-color: unset;
    padding-top: 26px;
  }

  .confirm-section-header p {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    padding: 15px;
    transform: matrix(1, -0.07, 0.04, 1, 0, 0);
    font-family: nandosHand;
  }

  .confirm-section-header p::after {
    display: block;
    width: 72px;
    height: 10px;
    content: "";
    background: url("../../assets/images/drawn_Line.svg") no-repeat;
    margin-left: 50%;
    transform: rotate(4deg) translate(-50%, 0);
    margin-top: 17px;
  }
  .overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
  }
  .nav-link-container-form {
    padding: 0px 0px 6px 0px;
    max-height: 42px;
    margin: 0 auto;
    position: relative;
    max-width: 486px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nav-link-container-form {
    padding: 0px 0px 6px 0px;
    max-width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .section-table-options {
    padding-left: 40px;
  }
  .section-table-container {
    min-width: 400px;
  }

  .section-tables {
    max-width: 486px;
  }
}

@media (min-width: 1024px) {
  .confirm-buttons-section {
    gap: 16px;
  }
  .last-saved-date {
    padding-bottom: 28px;
  }
  .confirm-section-content {
    height: 352px;
  }
  .page-container {
    padding-top: 61px;
  }
  .section-tables {
    display: grid;
    background: white;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid black;
    padding: 0 20px;
    max-width: 880px;
  }
  .section-table-container {
    margin-bottom: 0;
  }
  .heading,
  .section-table-options {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  .heading h3 {
    margin-top: 35px;
  }

  .section-table-options {
    display: block !important;
  }
  .chevron {
    display: none;
  }
  .nav-link-container-form {
    padding: 0px 0px 6px 0px;
    max-width: 940px;
  }
}
