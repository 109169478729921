.App {
  text-align: center;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/***** CUSTOM FONTS *****/
@font-face {
  font-family: nandosHand;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/nandos-hand/Nandos-Hand-Alt.woff") format("woff"),
    url("./assets/fonts/nandos-hand/Nandos-Hand-Alt.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: barlowRegular;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/barlow/barlow-regular.woff2") format("woff"),
    url("./assets/fonts/barlow/barlow-regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: barlowMedium;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/barlow/barlow-semibold.woff2") format("woff"),
    url("./assets/fonts/barlow/barlow-semibold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: barlowBold;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/barlow/barlow-bold.woff2") format("woff"),
    url("./assets/fonts/barlow/barlow-bold.ttf") format("truetype"); /* Safari, Android, iOS */
}
