.button-with-chevron {
  border: solid 1px black;
  background: white;
  width: 298px;
  margin: 16px;
  text-align: left;
  text-decoration: none;
  display: grid;
  grid-template-columns: auto 18px;
  align-items: center;
  padding: 13px 20px;
  cursor: pointer;
  margin-inline: auto;
}

.text-label {
  font-family: barlowMedium;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

svg.nav-item {
  text-align: left;
  position: absolute;
  left: 0;
  max-width: 32px;
}
