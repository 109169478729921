.checkbox {
  margin-bottom: 15px;
}
.checkbox .tag {
  color: #595959;
  display: block;
  float: left;
  font-weight: bold;
  position: relative;
  width: 120px;
}

.section-table-options .checkbox label {
  display: flex;
  align-items: center;
}

.checkbox label {
  align-items: start;
  display: inline-grid;
  position: relative;
  grid-template-columns: 26px auto;
  font-family: barlowRegular;
  font-weight: 400;
  font-size: 18px;
}
.readonly {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.checkbox input {
  display: none;
}
.section-tables .checkbox input:checked + label:after {
  top: 50%;
}

.section-tables .checkbox input + label:before {
  margin-top: 0px;
}

.checkbox input + label:before {
  content: "";
  background-color: #fafafa;
  border: 1.5px solid black;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  transition-duration: 0.3s;
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;
}
.checkbox input:checked + label:before {
  background-color: #000000;
}
.checkbox input:checked + label:after {
  content: "";
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  display: block;
  top: 14px;
  left: 6px;
  transform: rotate(45deg) translate(-50%, -50%);
}

.checkbox input:checked + label {
  font-family: barlowMedium;
  font-weight: 500;
}

.confirm-checkboxes {
  text-align: left;
  padding: 16px;
  padding-top: 57px;
}

@media (min-width: 768px) {
  .confirm-checkboxes {
    padding: 0 63px;
    padding-top: 40px;
  }
}
